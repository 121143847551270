import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
//
import Loadable from '../../../components/Loader/Loadable';
import AuthLayout from '../../../layout/AuthLayout';
//
const AuthLogin = Loadable(lazy(() => import('../../../screens/Auth/Login')));
const RoleSelection = Loadable(lazy(() => import('../../../screens/Auth/RoleSelection')));

const AuthenticationRoutes = () => {
  return [
    {
      path: '/',
      element: <AuthLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" replace /> },
        { path: 'login', element: <AuthLogin /> },
        { path: 'role-selection', element: <RoleSelection /> },
        { path: '*', element: <Navigate to="/login" replace /> }
      ]
    }
  ];
}
export default AuthenticationRoutes;