import axios from '../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const deleteEarning = createAsyncThunk(
  'earning/deleteEarning',
  async (selectedEarningID, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/claim/${selectedEarningID}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);