import { createSlice } from '@reduxjs/toolkit';

const setTimeSlotsSlice = createSlice({
  name: 'timeSlots',
  initialState: [],
  reducers: {
    setTimeSlots: (state, action) => {
      return action.payload;
    },
    clearTimeSlots: () => {
      return [];
    },
  },
});

export const { setTimeSlots, clearTimeSlots } = setTimeSlotsSlice.actions;

export default setTimeSlotsSlice.reducer;
