import axios from '../../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getCourier = createAsyncThunk(
    'getCourier',
    async (email, { rejectWithValue }) => {
      try {
        const response = await axios.get(`/user/${email}`);
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message);
      }
    }
  );