import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "../../utils/axios";

export const getRouteOrder = createAsyncThunk(
  'routeOrder/getRouteOrder',
  async (selectedBranchID, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/route/order/branch/${selectedBranchID}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);