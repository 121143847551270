import { useState, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
//
import AuthRoutes from './AuthRoutes';
import MainRoutes from './MainRoutes';
import Loader from '../../../components/Loader/Loader';

export default function Routes() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const role = useSelector((state) => state?.setRole?.data);
  const token = localStorage.getItem('token')
  const [authStatus, setAuthStatus] = useState('loading');

  useEffect(() => {
    if (isAuthenticated) {
      if(role?.data) {
        setAuthStatus('authenticated');
        sessionStorage.setItem('token', role.data.accessToken);
      } else {
        setAuthStatus('not-authenticated');
      }
    } else {
      setAuthStatus('not-authenticated');
    }
  }, [isAuthenticated, token, role ]);

  const mainRoutes = useRoutes(MainRoutes());
  const authRoutes = useRoutes(AuthRoutes());

  if (authStatus === 'loading') {
    return <Loader />;
  }

  return authStatus === "authenticated" ? mainRoutes : authRoutes;
}