import axios from '../../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const  putSubcontractor = createAsyncThunk(
    'putSubcontractor',
    async (updatedData, { rejectWithValue }) => {
      try {
        const response = await axios.put(`/user/subcontractor`, updatedData);
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message);
      }
    }
  ); 
