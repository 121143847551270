import axios from '../../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const  putClientOperations = createAsyncThunk(
    'putClientOperations',
    async (updatedData, { rejectWithValue }) => {
      try {
        const response = await axios.put(`/user/client-operations`, updatedData);
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message);
      }
    }
  ); 