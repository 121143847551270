import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "../../utils/axios";

export const getRouteByDate = createAsyncThunk(
  'route/getRouteListByDate',
  async ({ timestamp }, { rejectWithValue }) => {
    try {
      const refresh = JSON.parse(sessionStorage.getItem('refresh'));
      const response = await axios.post(`/route/routeListbyDate?timestamp=${timestamp}`, {
        districtIDs: refresh.data.districts,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);