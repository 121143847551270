import React, { useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const SearchBar = ({ placeholder, onSearch }) => {
  const [searchText, setSearchText] = useState('');

  const handleChange = e => {
    setSearchText(e.target.value);
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      onSearch(searchText);
    }
  };

  return (
    <SearchBarContainer>
      <SearchInput 
        type="text" 
        placeholder={placeholder} 
        value={searchText} 
        onChange={handleChange}
        onKeyPress={handleKeyPress}
      />
      <SearchIcon onClick={() => onSearch(searchText)} />
    </SearchBarContainer>
  );
};

const SearchBarContainer = styled.div`
  position: relative;
`;

const SearchInput = styled.input`
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  background-color: #f5f5f5;
  padding: 8px 30px 8px 10px;
  margin-left: 10px;
  font-size: 16px;
  color: #333;
  width: 200px;

  &:focus {
    border-color: #1890ff;
  }

  &::placeholder {
    color: #999;
  }
`;

const SearchIcon = styled(SearchOutlined)`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 18px;
  color: #999;
  cursor: pointer;
`;

export default SearchBar;
