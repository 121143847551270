import React, { useState , useEffect} from 'react';
import { Badge } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import ProfileSection from './ProfileSection';
import AppButton from '../../../components/Buttons/AppButton';
import SearchBar from './SearchBar';
import { NotificationDropdown } from './Notification';
import { useSelector , useDispatch} from 'react-redux';
import { getOrder } from '../../../core/store/order/getOrder.repository';
import SuccessModal from '../../../components/Models/SuccessModal';

const Header = ({ handleLeftDrawerToggle, collapsed, role, user }) => {
  const [showEnvironmentTag, setShowEnvironmentTag] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orders = useSelector((state) => state?.getOrder?.data);
  const [errorModalTitle, setErrorModalTitle] = useState("");
  const [searchedOrderId, setSearchedOrderId] = useState("");

  const handleToggleClick = () => {
    setShowEnvironmentTag(!showEnvironmentTag);
  };

  const handleDetail = (id) => {
    navigate(`/operation-order-management/detail/${id}`);
  };

  const handleSearch = (id) => {
    if (id) {
      const matchedOrder = orders.find(order => order.refID === id);
      if (matchedOrder) {
        handleDetail(matchedOrder._id);
      } else {
        setErrorModalTitle("Aradığınız Id'de bir sipariş bulunmamaktadır.");
        setSearchedOrderId(id);
      }
    }
  };
  
  useEffect(() => {
    dispatch(getOrder());
  }, [dispatch]);

  return (
    <HeaderContainer>
      <LeftContainer>
        {collapsed ? (
          <MenuUnfoldOutlined
            onClick={handleLeftDrawerToggle}
            style={{ fontSize: '20px', cursor: 'pointer', color: '#fff' }}
          />
        ) : (
          <MenuFoldOutlined
            onClick={handleLeftDrawerToggle}
            style={{ fontSize: '20px', cursor: 'pointer', color: '#fff' }}
          />
        )}
        {role === "operations" ? <SearchBar placeholder="Ara..." onSearch={handleSearch} /> : ""}
      </LeftContainer>
      <RightContainer>
        <NotificationDropdown />
        <Badge count={showEnvironmentTag ? process.env.REACT_APP_ENV : 0} style={{ backgroundColor: '#52c41a', bottom: "50%", top: "50%", right: 90 }}>
          <AppButton
            type="primary"
            icon={showEnvironmentTag ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            onClick={handleToggleClick}
          />
        </Badge>
        <ProfileSection role={role} user={user} />
      </RightContainer>
      <SuccessModal
        open={errorModalTitle !== ""}
        handleClose={() => setErrorModalTitle("")}
        title={errorModalTitle}
        titleColor="red"
        description={`Aradığınız sipariş ID: ${searchedOrderId}`}
      />
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: ${props => props.theme.laFerrari};
  height: 50px;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RightContainer = styled.div`
  display: flex;
  max-width: 450px;
`;

export default Header;
