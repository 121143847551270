import { createSlice } from '@reduxjs/toolkit';
import { putRouteApprove } from './putRouteApprove.repository';

export const putRouteApproveSlice = createSlice({
  name: 'routes',
  initialState: {
    loading: false,
    data: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(putRouteApprove.pending, (state) => {
        state.loading = true;
      })
      .addCase(putRouteApprove.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(putRouteApprove.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default putRouteApproveSlice.reducer;