import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "../../utils/axios";

export const putRouteDisapprove = createAsyncThunk(
  'routes/putRouteApprove',
  async (selectedRouteID, thunkAPI) => {
    try {
      const response = await axios.put(`/route/disapprove/${selectedRouteID}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);