import { createSlice } from "@reduxjs/toolkit";
import { deleteNotification } from './deleteNotification.repository';

const initialState = {
    loading: 'idle',
    data: null,
    error: null
  };

const deleteNotificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(deleteNotification.pending, (state) => {
          state.loading = 'loading';
        })
        .addCase(deleteNotification.fulfilled, (state) => {
          state.loading = 'idle';
          state.error = null;
        })
        .addCase(deleteNotification.rejected, (state, action) => {
          state.loading = 'idle';
          state.error = action.payload;
        });
    },
  });
  
  export default deleteNotificationSlice.reducer;