import axios from '../../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const  deleteClientOperations = createAsyncThunk(
    'deleteClientOperations',
    async (email, { rejectWithValue }) => {
      try {
        const response = await axios.delete(`/user/${email}/client-operations`);
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message);
      }
    }
  ); 

  