import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "../../utils/axios";

export const getRouteVehicle = createAsyncThunk(
  'routeVehicle/getRouteVehicle',
  async (_, { rejectWithValue }) => {
    try {
      const refresh = JSON.parse(sessionStorage.getItem('refresh'));
      const response = await axios.post('/route/vehicleList', {
        districtIDs: refresh.data.districts
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
