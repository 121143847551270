import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "../../utils/axios";

export const getRoute = createAsyncThunk(
  'route/getRoute',
  async (_, { rejectWithValue }) => {
    try {
      const refresh = JSON.parse(sessionStorage.getItem('refresh'));
      const response = await axios.post(`/route/routeList`, {
        districtIDs: refresh.data.districts,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);