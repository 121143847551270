import { useSelector } from 'react-redux';
//
import { customerOperation } from './customerOperation';
import { customerManager } from './customerManager';
import { operation } from './operation';
import { supervisor } from './supervisor';

const MenuItems = () => {
  const role = useSelector((state) => state.setRole?.data?.data?.scope);
  let items = [];

  switch (role) {
    case 'operations':
      items = [...Object.values(operation)];
      break;
    case 'supervisor':
      items = [...Object.values(supervisor)];
      break;
    case 'clientOperations':
      items = [...Object.values(customerOperation)];
      break;
    case 'clientManager':
      items = [...Object.values(customerManager)];
      break;
    default:
      return { items: [] };
  }

  return { items };
};

export default MenuItems;