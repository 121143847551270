import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import LogoSection from "../LogoSection";
import MenuItems from './MenuItems';

const { SubMenu } = Menu;

const getBasePath = (path) => {
  const match = path.match(/^\/[^/]+/); 
  return match ? match[0] : path;
};

const SiderMenu = () => {
  const { items } = MenuItems();
  const location = useLocation();

  const selectedKey = getBasePath(location.pathname);

  return (
    <div style={{ background: '#fff', height: '100vh' }}>
      <LogoSection />
      <Menu theme="light" mode="inline" selectedKeys={[selectedKey]}>
        {items.map((group) =>
          group.type === 'group' ? (
            <SubMenu key={group.id} title={group.title} icon={group.icon}>
              {group.items.map((item) => (
                <Menu.Item key={getBasePath(item.url)} icon={item.icon} disabled={item.disabled}>
                  <Link to={item.url}>
                    {item.title}
                  </Link>
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            group.items.map((item) => (
              <Menu.Item key={getBasePath(item.url)} icon={item.icon} disabled={item.disabled}>
                <Link to={item.url}>
                  {item.title}
                </Link>
              </Menu.Item>
            ))
          )
        )}
      </Menu>
    </div>
  );
};

export default SiderMenu;
