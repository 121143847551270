import axios from '../../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const  putVehicle = createAsyncThunk(
    'putVehicle',
    async (updatedData, { rejectWithValue }) => {
      try {
        const response = await axios.put(`/user/vehicle`, updatedData);
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message);
      }
    }
  ); 
