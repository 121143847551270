import { createSlice } from '@reduxjs/toolkit';
import { setRouteReportFilter } from './setRouteReportFilter.repository';

const initialState = {
  loading: false,
  data: null,
  error: null
};

const setRouteReportFilterSlice = createSlice({
  name: 'setRouteReportFilter',
  initialState,
  reducers: {
    clearData: (state) => {
      state.data = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(setRouteReportFilter.pending, (state) => {
        state.loading = true;
      })
      .addCase(setRouteReportFilter.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(setRouteReportFilter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});
export const { clearData } = setRouteReportFilterSlice.actions;
export default setRouteReportFilterSlice.reducer;