import axios from '../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const patchDebitDismiss = createAsyncThunk(
    'debitDismiss/patchDebitDismiss',
    async ({orderIDs}, { rejectWithValue }) => {
      try {
        const response = await axios.patch(`/debit/dismiss`, { orderIDs });
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message);
      }
    }
);