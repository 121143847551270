import { createSlice } from '@reduxjs/toolkit';
import { getPlannerOrder } from './getPlannerOrder.repository';

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const getPlannerOrderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    clearOrders: (state) => {
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPlannerOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPlannerOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(getPlannerOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearOrders } = getPlannerOrderSlice.actions;

export default getPlannerOrderSlice.reducer;
