import axios from '../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const cancelOrder = createAsyncThunk(
  'order/cancelOrder',
  async (orderIDs, { rejectWithValue }) => {
    try {
      const response = await axios.patch('/order/cancel', { orderIDs });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);