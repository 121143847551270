// assets
import { DashboardOutlined } from '@ant-design/icons';

// constant
const icons = { DashboardOutlined };


// ============================== DASHBOARD MENU ITEMS ============================== //

const customerManagerDashboard = {
    id: 'customeManager-dashboard',
    title: 'Ön İzleme',
    type: 'group',
    items: [
        {
            id: 'customerManager-dashboard',
            title: 'Ön İzleme',
            type: 'item',
            url: '/customerManager-dashboard',
            icon: <icons.DashboardOutlined />
        }
    ]
}

export const customerManager = { customerManagerDashboard };


