import axios from '../../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const  putClientManager = createAsyncThunk(
    'putClientManager',
    async (updatedData, { rejectWithValue }) => {
      try {
        const response = await axios.put(`/user/client-manager`, updatedData);
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message);
      }
    }
  ); 
