import axios from '../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const  putProject = createAsyncThunk(
    'project/putProject',
    async ({projectId, updatedData}, { rejectWithValue }) => {
      try {
        const response = await axios.put(`/project/${projectId}`, updatedData);
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message);
      }
    }
  ); 
