import axios from '../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const setEarning = createAsyncThunk(
  'earning/setEarning',
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await axios.post('/claim', requestData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);