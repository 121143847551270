import { createSlice } from '@reduxjs/toolkit';
import { getPlannerOrderCounts } from './getPlannerOrderCounts.repository';

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const getPlannerOrderCountSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    clearOrders: (state) => {
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPlannerOrderCounts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPlannerOrderCounts.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(getPlannerOrderCounts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearOrders } = getPlannerOrderCountSlice.actions;

export default getPlannerOrderCountSlice.reducer;
