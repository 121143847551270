import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getLocations = createAsyncThunk(
    'locations/getLocations',
    async ({ routeIDs, vehicle }, { rejectWithValue }) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/mobile/location`, {
          params: {
            routeIDs,
            vehicle
          }
        });
        const data = response.data.data;
        return data;
      } catch (err) {
        return rejectWithValue(err.message);
      }
    }
);