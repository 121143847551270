import { BellOutlined, CloseOutlined } from '@ant-design/icons';
import { Badge, Button, Dropdown, Menu, Popconfirm } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
//
import { deleteNotification } from '../../../../core/store/notification/deleteNotification.repository';
import { getNotification } from '../../../../core/store/notification/getNotification.repository';
import { readNotification } from '../../../../core/store/notification/readNotification.repository';
import { unreadNotification } from '../../../../core/store/notification/unreadNotification.repository';
import Loader from './../../../../components/Loader/Loader';

const StyledDropdown = styled(Dropdown)`
  margin-right: 20px;
`;

const NotificationItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const NotificationDropdown = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.getNotification.data);

  useEffect(() => {
    dispatch(getNotification());
  }, [dispatch]);

  if (!notifications) {
    return <Loader />;
  }

  const handleDelete = (notificationId) => {
    dispatch(deleteNotification(notificationId));
  };

  const handleReadAll = () => {
    const notificationIds = notifications.map(notification => notification.id);
    dispatch(readNotification(notificationIds));
  };

  const handleUnread = (notificationId) => {
    dispatch(unreadNotification(notificationId));
  };

  const menu = (
    <Menu>
      {notifications.map((notification, index) => (
        <Menu.Item key={index} onClick={() => handleUnread(notification.id)}>
          <NotificationItem>
            {notification}
            <Popconfirm title="Bu bildirimi silmek istediğinize emin misiniz?" okText="Evet" cancelText="Hayır" onConfirm={() => handleDelete(notification.id)}>
              <CloseOutlined />
            </Popconfirm>
          </NotificationItem>
        </Menu.Item>
      ))}
      <Menu.Item>
        <Button type="link" onClick={handleReadAll}>Hepsini Okundu Olarak İşaretle</Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <div style={{ display: "flex" }}>
      <StyledDropdown overlay={menu} trigger={['click']}>
        <Badge count={notifications.length}>
          <Button shape="circle" icon={<BellOutlined />} />
        </Badge>
      </StyledDropdown>
    </div>
  );
};