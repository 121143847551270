import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getLastLocation = createAsyncThunk(
    'locations/getLastLocation',
    async (_, { rejectWithValue }) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/mobile/lastLocation`);
        const data = response.data.data;
        return data;
      } catch (err) {
        return rejectWithValue(err.message);
      }
    }
);