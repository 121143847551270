import { createSlice } from "@reduxjs/toolkit";
import { putRoutePrice } from "./putRoutePrice.repository";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const putRoutePriceSlice = createSlice({
  name: "putRoutePrice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(putRoutePrice.pending, (state) => {
        state.loading = true;
      })
      .addCase(putRoutePrice.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(putRoutePrice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default putRoutePriceSlice.reducer;
