import axios from '../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getEnums = createAsyncThunk(
    'enums',
    async (_, { rejectWithValue }) => {
      try {
        const response = await axios.get(`/common/enums`);
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message);
      }
    }
  );