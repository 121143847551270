import axios from '../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getProject = createAsyncThunk(
    'project/getProject',
    async (_, { rejectWithValue }) => {
      try {
        const response = await axios.get(`/project`);
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message);
      }
    }
  );