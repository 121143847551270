import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, Dropdown, Button, Modal } from 'antd';
import { UserOutlined, LogoutOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
//
import { logout } from '../../../../core/store/auth/logout';
import { roleTranslations } from '../../../../core/utils/constants';
import RoleSelect from '../../../../screens/App/Profile/RoleSelect';

const ProfileSection = ({ role, user }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedKey, setSelectedKey] = useState('');
  const roleData = useSelector((state) => state.setRole?.data?.data);
  const userRole = roleData?.scopes ? roleData?.scopes : [];
  const refreshToken = roleData?.refreshToken;
  const currentRole = roleData?.scope

  const handleMenuClick = ({ key }) => {
    setSelectedKey(key);

    if (key === 'logout') {
      dispatch(logout());
      navigate('/login');
    } else if (key === 'profile') {
      navigate('/profile');
    } else if (key === 'deleteData') {
      Modal.confirm({
        title: 'Verileri temizlemek istediğine emin misin?',
        onOk() {
          deleteData();
        },
      });
    }
  };

  const deleteData = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_SEED_BASE_URL, {
        params: {
          currentCount: 2,
          subcontractorCount: 10,
          operationUserCount: 0,
          supervisorUserCount: 0,
          clientOperationUserCountPerCurrent: 0,
          clientManagerUserCountPerCurrent: 0,
          projectCountPerCurrent: 1,
          branchCountPerProject: 1,
          orderCountPerBranch: 50,
        },
      });
      console.log('Data deletion successful:', response.data);
      window.location.reload();
    } catch (error) {
      console.error('Failed to delete data:', error);
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick} selectedKeys={[selectedKey]}>
      <Menu.Item key="profile" icon={<UserOutlined />}>
        Profil
      </Menu.Item>
      <Menu.Item key="roleSelect">
        <RoleSelect
          userData={user}
          userRole={userRole}
          refreshToken={refreshToken}
          currentRole={currentRole}
        />
      </Menu.Item>
      <Menu.Item key="deleteData" icon={<DeleteOutlined />}>
        Verileri Temizle
      </Menu.Item>
      <Menu.Item key="logout" icon={<LogoutOutlined />}>
        Çıkış Yap
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button>
        <UserOutlined /> {user?.displayName}, {role && roleTranslations[role.toLowerCase()]}
      </Button>
    </Dropdown>
  );
};

export default ProfileSection;