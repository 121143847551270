import axios from '../../../utils/axios'
import { createAsyncThunk } from '@reduxjs/toolkit';

export const  deleteCurrent = createAsyncThunk(
    'current/deleteCurrent',
    async (currentId, { rejectWithValue }) => {
      try {
        const response = await axios.delete(`/current/${currentId}`);
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message);
      }
    }
  ); 

  