import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "../../utils/axios";

export const setOrderTransfer = createAsyncThunk(
  'orderTransfer/setOrderTransfer',
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await axios.post('/route/order/transfer',requestData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);