import { ConfigProvider } from 'antd';
import trTr from "antd/lib/locale/tr_TR";
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persister } from './core/store/store';
import { LoadScript } from '@react-google-maps/api';
//
import App from './App';
import { ThemeContext } from './core/utils/contexts/themeContext';
import { colors } from './core/utils/helpers/theme';
import Loader from './components/Loader/Loader';

const libraries = ["places", "drawing", "geometry"];


const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <PersistGate loading={<Loader />} persistor={persister}>
      <BrowserRouter>
        <ConfigProvider locale={trTr}>
          <ThemeContext.Provider value={colors}>
            <ThemeProvider theme={colors}>
              <LoadScript
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                libraries={libraries}
                loadingElement={<Loader />
              }
              >
                <App />
              </LoadScript>
            </ThemeProvider>
          </ThemeContext.Provider>
        </ConfigProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);