// assets
import {
  DashboardOutlined, FileTextOutlined, ApartmentOutlined,
  UserSwitchOutlined, BankOutlined, UserOutlined,
} from '@ant-design/icons';

// constant
const icons = {
  DashboardOutlined, FileTextOutlined, ApartmentOutlined,
  UserSwitchOutlined, BankOutlined, UserOutlined
};

const supervisorDashboard = {
  id: 'supervisor-dashboard',
  title: 'Ön İzleme',
  type: 'item',
  items: [
    {
      id: 'supervisor-dashboard',
      title: 'Ön İzleme',
      type: 'item',
      url: '/supervisor-dashboard',
      icon: <icons.DashboardOutlined />
    },
  ]
}

const projectManagement = {
  id: 'supervisor-project-management',
  title: 'Proje',
  type: 'item',
  items: [
    {
      id: 'supervisor-project-management',
      title: 'Proje',
      type: 'item',
      url: '/supervisor-project-management',
      icon: <icons.ApartmentOutlined />
    },
  ]
}

const branchManagement = {
  id: 'supervisor-branch-management',
  title: 'Şube',
  type: 'item',
  items: [
    {
      id: 'supervisor-branch-management',
      title: 'Şube',
      type: 'item',
      url: '/supervisor-branch-management',
      icon: <icons.BankOutlined />
    },
  ]
}

const userManagement = {
  id: 'supervisor-user',
  title: 'Kullanıcı',
  type: 'group',
  icon: <icons.UserSwitchOutlined />,
  items: [
    {
      id: 'supervisor-courier-management',
      title: 'Kurye',
      type: 'item',
      url: '/supervisor-courier-management',
      icon: <icons.UserOutlined />
    },
    {
      id: 'supervisor-supervisor-management',
      title: 'Yönetici',
      type: 'item',
      url: '/supervisor-supervisorUser-management',
      icon: <icons.UserOutlined />
    },

    {
      id: 'supervisor-operation-management',
      title: 'Operasyon',
      type: 'item',
      url: '/supervisor-operation-management',
      icon: <icons.UserOutlined />
    },
    {
      id: 'supervisor-clientOperations-management',
      title: 'Müşteri Operasyon',
      type: 'item',
      url: '/supervisor-clientOperation-management',
      icon: <icons.UserOutlined />
    },
    {
      id: 'supervisor-clientManager-management',
      title: 'Müşteri Yönetici Yönetimi',
      type: 'item',
      url: '/supervisor-clientManager-management',
      icon: <icons.UserOutlined />
    },
    {
      id: 'supervisor-subcontractor-management',
      title: 'Taşeron',
      type: 'item',
      url: '/supervisor-subcontractor-management',
      icon: <icons.UserOutlined />
    },
    {
      id: 'supervisor-vehicle-management',
      title: 'Araç',
      type: 'item',
      url: '/supervisor-vehicle-management',
      icon: <icons.UserOutlined />
    },
  ]
};

const supervisorAccounting = {
  id: 'supervisor-accounting',
  title: 'Muhasebe',
  type: 'group',
  icon: <icons.FileTextOutlined />,
  items: [
    {
      id: 'current',
      title: 'Cari',
      type: 'item',
      url: '/current',
      icon: <icons.FileTextOutlined />
    },
    // {
    //   id: 'courier-agreement',
    //   title: 'Kurye Mutabakat',
    //   type: 'item',
    //   url: '/courier-agreement',
    //   icon: icons.IconFileInvoice
    // },
    // {
    //   id: 'customer-agreement',
    //   title: 'Müşteri Mutabakat',
    //   type: 'item',
    //   url: '/customer-agreement',
    //   icon: icons.IconFileInvoice
    // }
  ]
}

// const supervisorPlanner = {
//   id: 'planner-supervisor',
//   title: 'Planlama',
//   type: 'group',
//   items: [
//     {
//       id: 'supervisor-liveTracking',
//       title: 'Canlı Takip',
//       type: 'item',
//       url: '/supervisor-live-tracking',
//       icon: icons.IconVideo
//     },
//     {
//       id: 'supervisor-courier-debit',
//       title: 'Kurye Zimmet',
//       type: 'item',
//       url: '/supervisor-courier-debit',
//       icon: icons.IconLockOff
//     },
//   ]
// }


// const supervisorReport = {
//   id: 'supervisor-report',
//   title: 'Raporlar',
//   type: 'group',
//   items: [
//     {
//       id: 'supervisor-operation-time',
//       title: 'Çalışma Süresi',
//       type: 'item',
//       url: '/supervisor-operation-time',
//       icon: icons.IconReportAnalytics
//     },
//     {
//       id: 'supervisor-distance',
//       title: 'Mesafe',
//       type: 'item',
//       url: '/supervisor-distance',
//       icon: icons.IconReportAnalytics
//     },
//     {
//       id: 'supervisor-order',
//       title: 'Sipariş',
//       type: 'item',
//       url: '/supervisor-order',
//       icon: icons.IconReportAnalytics
//     },
//     {
//       id: 'supervisor-performance',
//       title: 'Performans',
//       type: 'item',
//       url: '/supervisor-performance',
//       icon: icons.IconReportAnalytics
//     },
//     {
//       id: 'supervisor-customer-invoice',
//       title: 'Müşteri Faturası',
//       type: 'item',
//       url: '/supervisor-customer-invoice',
//       icon: icons.IconReportAnalytics
//     },
//     {
//       id: 'supervisor-profitability',
//       title: 'Kararlılık',
//       type: 'item',
//       url: '/supervisor-profitability',
//       icon: icons.IconReportAnalytics
//     },
//     {
//       id: 'supervisor-courier-invoice',
//       title: 'Kureye Faturası',
//       type: 'item',
//       url: '/supervisor-courier-invoice',
//       icon: icons.IconReportAnalytics
//     }
//   ]
// };

export const supervisor = {
  supervisorDashboard,
  projectManagement,
  branchManagement,
  userManagement,
  supervisorAccounting,
  // supervisorPlanner, 
  // supervisorReport 
};


