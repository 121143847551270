import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "../../utils/axios";

export const getUserProfile = createAsyncThunk(
  'userProfile/getUserProfile',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/user/profile`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);