import axios from '../../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const setClientManager = createAsyncThunk(
    'setClientManager',
    async (requestData, { rejectWithValue }) => {
      try {
        const response = await axios.post('/user/client-manager', requestData);
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message);
      }
    }
  ); 

