import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { message } from 'antd';
import {setRole as setRoleInState} from './setRole.slice'

export const setRole = createAsyncThunk(
    'role/setRole',
    async ({ refreshToken, role }, thunkAPI) => {
        try {
            const response = await axios.post(process.env.REACT_APP_AUTH_BASE_URL, {
                refreshToken: refreshToken,
                grantType: 'refresh_token',
                scope: role,
                platform: 'portal',
            });
            thunkAPI.dispatch(setRoleInState(response.data));
            sessionStorage.setItem('refresh', JSON.stringify(response.data));
            return response.data;
        } catch (error) {
            message.error(error.message);
            return thunkAPI.rejectWithValue(error);
        }
    }
);