import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

export const putRoutePrice = createAsyncThunk(
  "routePrice/putRoutePrice",
  async ({ selectedRouteID, routePrice }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `/route/routePriceChange/${selectedRouteID}`, { routePrice }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);