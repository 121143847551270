import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "../../utils/axios";

export const getRouteBranch = createAsyncThunk(
  'routeBranch/getRouteBranch',
  async (selectedDistrictID, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/route/branch/${selectedDistrictID}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);