import { initializeApp } from "@firebase/app";
import { getMessaging } from "firebase/messaging";
import { getFirestore, collection, onSnapshot } from "firebase/firestore";
import {
  getAuth,
  signInWithCustomToken,
  onAuthStateChanged,
} from "firebase/auth";
import { setRole } from "../core/store/auth/setRole.repository";
import { getRoute } from "../core/store/route/getRoute.repository";
import { getOrder } from "../core/store/order/getOrder.repository";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
export const messaging = getMessaging(firebaseApp);
export const firestore = getFirestore(firebaseApp);

export const listenToCollectionChanges = (dispatch) => {
  const collectionRef = collection(firestore, "trigger");

  const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      if (change.type === "modified") {
        const data = change.doc.data();
        console.log("Modified document: ", data);
        if (
          data.targetDataSource === "mobile/order/origin/arrived" ||
          data.targetDataSource === "mobile/order/destination/arrived"
        ) {
          dispatch(getOrder());
        } else if (
          data.targetDataSource === "mobile/route/start" ||
          data.targetDataSource === "mobile/route/deliver" ||
          data.targetDataSource === "mobile/debit" ||
          data.targetDataSource === "mobile/order/undelivered" ||
          data.targetDataSource === "mobile/order/delivery-reset" ||
          data.targetDataSource === "route/end"||
          data.targetDataSource === "mobile/route/booking"||
          data.targetDataSource === "mobile/route/bid" ||
          data.targetDataSource === "mobile/route/assigned"
        ) {
          dispatch(getRoute());
          dispatch(getOrder());
        }
      }
    });
  });

  return unsubscribe;
};

export const signInWithToken = async (token, dispatch, role, refresh) => {
  try {
    const userCredential = await signInWithCustomToken(auth, token);
    const user = userCredential.user;
    console.log("User signed in", user);

    onAuthStateChanged(auth, (user) => {
      if (!user) {
        const refreshToken = sessionStorage.getItem("refresh");
        if (refreshToken) {
          dispatch(setRole({ refreshToken: refresh, role: role }));
        }
      }
    });
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log("Error signing in", errorCode, errorMessage);
  }
};
