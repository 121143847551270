import { createSlice } from '@reduxjs/toolkit';
import { authLogin } from './auth.repository';
import { logout } from './logout';

const initialState = {
  isLoading: false,
  data: null,
  error: null,
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authLogin.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(authLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.isAuthenticated = true;
      })
      .addCase(authLogin.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isAuthenticated = false;
      });
  },
});


export default authSlice.reducer;