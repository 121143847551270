import { createSlice } from '@reduxjs/toolkit';
import { putRouteDisapprove } from './putRoutDisapprove.repository';

export const putRouteDisapproveSlice = createSlice({
  name: 'routes',
  initialState: {
    loading: false,
    data: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(putRouteDisapprove.pending, (state) => {
        state.loading = true;
      })
      .addCase(putRouteDisapprove.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(putRouteDisapprove.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default putRouteDisapproveSlice.reducer;