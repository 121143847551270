// RoleSelect.js
import React, { useState } from 'react';
import { Radio } from 'antd';
import { useDispatch } from 'react-redux';
//
import { roleTranslations } from '../../../../core/utils/constants';
import { getUserProfile } from '../../../../core/store/profile/getUser.repository';
import { setRole } from '../../../../core/store/auth/setRole.repository';

const RoleSelect = ({ userRole, refreshToken, currentRole}) => {
  const [selectedRole, setSelectedRole] = useState(currentRole);
  const dispatch = useDispatch();

  const handleRoleChange = (event) => {
    const selectedRole = event.target.value;
    setSelectedRole(selectedRole);

    if (selectedRole && refreshToken) {
      dispatch(setRole({ refreshToken: refreshToken, role: selectedRole}));
      dispatch(getUserProfile());
    }
  };

  return (
    <Radio.Group onChange={handleRoleChange} value={selectedRole}>
      {userRole?.map((role) => (
        <Radio value={role} key={role}>
          {roleTranslations[role.toLowerCase()] || role}
        </Radio>
      ))}
    </Radio.Group>
  );
};

export default RoleSelect;