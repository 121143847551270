// storage.slice.js
import { createSlice } from '@reduxjs/toolkit';
import { uploadFile } from './setUploadFile.repository';

const initialState = {
  loading: 'idle',
  data: null,
  error: null,
};

const storageSlice = createSlice({
  name: 'storage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadFile.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.data = action.payload;
        state.error = null;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.loading = 'idle';
        state.error = action.payload;
      });
  },
});

export default storageSlice.reducer;