import axios from '../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getOrder = createAsyncThunk(
    'order/getOrder',
    async (_, { rejectWithValue }) => {
      try {
        const response = await axios.get(`/order`);
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message);
      }
    }
  );