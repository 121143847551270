import { createSlice } from '@reduxjs/toolkit';
import { logout } from './logout';

const initialState = {
    data: null
};

const setRoleSlice = createSlice({
    name: 'role',
    initialState,
    reducers: {
        setRole: (state, action) => {
            state.data = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logout.fulfilled, (state) => {
            state.data = null;
        });
    }
});

export const { setRole } = setRoleSlice.actions;

export default setRoleSlice.reducer;