import { createSlice } from '@reduxjs/toolkit';
import { getSupervisor } from './getSupervisor.repository';

const initialState = {
  loading: 'idle',
  data: null,
  error: null
};

const getSupervisorSlice = createSlice({
  name: 'getSupervisor',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSupervisor.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(getSupervisor.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.data = action.payload;
        state.error = null;
      })
      .addCase(getSupervisor.rejected, (state, action) => {
        state.loading = 'idle';
        state.error = action.payload;
      });
  },
});

export default getSupervisorSlice.reducer;