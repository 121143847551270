import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getUploadFile = createAsyncThunk(
    'upload/getUploadFile',
    async ({ storageBaseUrl, fileName, storageToken, formData }) => {
        try {
          const token = sessionStorage.getItem('token'); 
            const response = await axios.get(`${storageBaseUrl}/${fileName}/${storageToken}`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (err) {
            // Handle error
            throw new Error(err.message);
        }
    }
);
