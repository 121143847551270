import { createSlice } from '@reduxjs/toolkit';
import { deleteVehicle } from './deleteVehicle.repository';

const initialState = {
  loading: 'idle',
  data: null,
  error: null
};

const deleteVehicleSlice = createSlice({
  name: 'deleteVehicle',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteVehicle.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(deleteVehicle.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.data = action.payload;
        state.error = null;
      })
      .addCase(deleteVehicle.rejected, (state, action) => {
        state.loading = 'idle';
        state.error = action.payload;
      });
  },
});

export default deleteVehicleSlice.reducer;