import { Spin } from "antd";
import styled from "styled-components";

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const StyledSpin = styled.div`
  .ant-spin-dot-item {
    background-color: #f00;
  }
`;

const Loader = () => (
    <LoaderWrapper>
        <StyledSpin>
            <Spin size="default" />
        </StyledSpin>
    </LoaderWrapper>
);

export default Loader;