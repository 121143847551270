import axios from '../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const readNotification = createAsyncThunk(
    'notification/readNotification',
    async (selectedNotificationID, { rejectWithValue }) => {
      try {
        const response = await axios.patch(`/notification/read`, {
          notifications: [selectedNotificationID]
        });
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message);
      }
    }
);