import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "../../utils/axios";

export const getRouteDetail = createAsyncThunk(
  'route/getRouteDetail',
  async (selectedRouteID, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/route/detail/${selectedRouteID}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);