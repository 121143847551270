// assets
import { DashboardOutlined, ShoppingOutlined } from '@ant-design/icons';

// constant
const icons = { DashboardOutlined, ShoppingOutlined };

// ============================== DASHBOARD MENU ITEMS ============================== //

const customerOperationDashboard = {
    id: 'customerOperation-dashboard',
    title: 'Ön İzleme',
    type: 'group',
    items: [
        {
            id: 'customerOperation-dashboard',
            title: 'Ön İzleme',
            type: 'item',
            url: '/customerOperation-dashboard',
            icon: <icons.DashboardOutlined />
        }
    ]
}

const customerManagement = {
    id: 'customer-management',
    title: 'Yönetim',
    type: 'group',
    items: [
        {
            id: 'customer-order',
            title: 'Sipariş',
            type: 'item',
            url: '/customer-order',
            icon: <icons.ShoppingOutlined />
        }
    ]
}

export const customerOperation = {customerOperationDashboard, customerManagement};


