import axios from '../../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getVehicle = createAsyncThunk(
    'getVehicle',
    async (email, { rejectWithValue }) => {
      try {
        const response = await axios.get(`/user/${email}`);
        return response.data;
      } catch (err) {
        if (err.response && err.response.status === 400) {
          throw new Error("Araç bilgileri alınırken bir hata oluştu. Lütfen tekrar deneyin.");
        } else {
          return rejectWithValue(err.message);
        }
      }
    }
  );

