import axios from '../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const setProject = createAsyncThunk(
    'project/setProject',
    async (requestData, { rejectWithValue }) => {
      try {
        const response = await axios.post('/project', requestData);
        return response.data;
      } catch (err) {
        if (!err.response) {
          // If no response object is available, throw the original error
          throw err;
        }
        if (err.response.status >= 400 && err.response.status < 500) {
          return rejectWithValue(err.response.data);
        } else {
          throw err;
        }
      }
    }
);
