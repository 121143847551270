import { createSlice } from '@reduxjs/toolkit';
import { getUploadFile } from './getUploadFile.repository';

const initialState = {
  loading: 'idle',
  data: null,
  error: null
};

const getUploadFileSlice = createSlice({
  name: 'uploadFile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUploadFile.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(getUploadFile.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.data = action.payload;
        state.error = null;
      })
      .addCase(getUploadFile.rejected, (state, action) => {
        state.loading = 'idle';
        state.error = action.payload;
      });
  },
});

export default getUploadFileSlice.reducer;