import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "../../utils/axios";

export const putRouteVehicle = createAsyncThunk(
  'routeVehicle/putRouteVehicle',
  async (vehicleData, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/route/vehicle`, vehicleData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);