import axios from '../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getPlannerOrderCounts = createAsyncThunk(
  'order/fetchOrderCountListBySlotTime',
  async ({ branchCode, timePeriods }, { rejectWithValue }) => {
    try {
      const requestBody = {
        branchCode,
        timePeriods,
        type: "notRouted"
      };
      const response = await axios.post('/order/orderListbyTimeWindowCount', requestBody);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);