import axios from '../../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getVehicleList = createAsyncThunk(
    'getVehicleList',
    async (_, { rejectWithValue }) => {
      try {
        const response = await axios.get(`/user?roles=vehicleRole`);
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message);
      }
    }
  );