import axios from '../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const setBranch = createAsyncThunk(
    'branch/setBranch',
    async (requestData, { rejectWithValue }) => {
        try {
            const response = await axios.post('/branch', requestData);
            return response.data;
        } catch (error) {
            // Sunucu hatası durumunda status kodunu kontrol ediyoruz
            if (error.response && error.response.status === 502) {
                // 502 Bad Gateway hatası durumunda özel bir hata mesajı dönüyoruz
                return rejectWithValue('502 Bad Gateway hatası oluştu, lütfen daha sonra tekrar deneyin.');
            } else {
                // Diğer hatalar için genel bir hata mesajı dönüyoruz
                return rejectWithValue('Bir hata oluştu, lütfen daha sonra tekrar deneyin.');
            }
        }
    }
); 


 