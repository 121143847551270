import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "../../utils/axios";

export const setRoute = createAsyncThunk(
  'route/setRoute',
  async (orderIDList, { rejectWithValue }) => {
    try {
      const response = await axios.post('/route', { 
        orders: orderIDList,
        vehicles: [],
        ignoreTimeWindow: true,
        optimizeWaypoints: true
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);