import axios from '../../../utils/axios'
import { createAsyncThunk } from '@reduxjs/toolkit';

export const  getCurrentId = createAsyncThunk(
    'current/getCurrentId',
    async (id, { rejectWithValue }) => {
      try {
        const response = await axios.get(`/current/${id}`);
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message);
      }
    }
  ); 
