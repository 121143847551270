// getNotification.slice.js
import { createSlice } from '@reduxjs/toolkit';
import { unreadNotification } from './unreadNotification.repository';

const initialState = {
  loading: 'idle',
  data: null,
  error: null
};

const unreadNotificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(unreadNotification.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(unreadNotification.fulfilled, (state) => {
        state.loading = 'idle';
        state.error = null;
      })
      .addCase(unreadNotification.rejected, (state, action) => {
        state.loading = 'idle';
        state.error = action.payload;
      });
  },
});

export default unreadNotificationSlice.reducer;