import axios from '../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const setDebitVehicle = createAsyncThunk(
    'debitVehicle/setDebitVehicle',
    async ({selectedVehicleID, startDate, endDate}, { rejectWithValue }) => {
      try {
        const response = await axios.post(`/debit/${selectedVehicleID}`, { startDate, endDate });
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message);
      }
    }
);