import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "../../utils/axios";

export const setDebitReportFilter = createAsyncThunk(
  'debitReportFilter/setDebitReportFilter',
  async ({selectedBranchID, startDate, endDate}, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/report/debit/${selectedBranchID}`, { startDate, endDate });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);