import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "../../utils/axios";

export const getSelectedProjects = createAsyncThunk(
  'selectedProjects/getSelectedProjects',
  async ({projectIDs}, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/project/selectedProjects`, { projectIDs });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);