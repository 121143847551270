// debit.repository.js
import axios from '../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const setDebit = createAsyncThunk(
  'debit/setDebit',
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await axios.post('/debit', requestData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);