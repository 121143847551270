import axios from '../../../utils/axios'
import { createAsyncThunk } from '@reduxjs/toolkit';

export const putCurrent = createAsyncThunk(
    'current/putCurrent',
    async ({ currentId, updatedData }, { rejectWithValue }) => {
        try {
            const response = await axios.put(`/current/${currentId}`, updatedData);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);
