import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
//
import Logo from '../../../assets/images/logo.png';
import { menuOpen } from '../../../core/store/customization/customization.slice';

const LogoSection = () => {
  const defaultId = useSelector((state) => state.customization.defaultId);
  const dispatch = useDispatch();

  return (
    <div 
      onClick={() => dispatch(menuOpen({ id: defaultId }))}
      style={{ height: '50px', background: "#fff", display: 'flex', 
      justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
    >
      <Link to="/">
        <img width={60} src={Logo} alt="logo" />
      </Link>
    </div>
  );
};

export default LogoSection;