import axios from '../../utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const setOrderForm = createAsyncThunk(
    'order/setOrderForm',
    async (requestData, { rejectWithValue }) => {
      try {
        const response = await axios.post('/order/form', requestData);
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message);
      }
    }
  ); 

  
   